<template>
  <q-form ref="formEle" class="q-gutter-md">
    <q-input
      label-slot
      v-model="form.fieldName"
      :rules="rules.fieldName"
      :readonly="readonly"
      maxlength="128"
      dense
      clearable
      outlined
      hide-bottom-space
    >
      <template v-slot:label>
        <span class="text-negative">*</span>
        <span>字段名称</span>
      </template>
    </q-input>
    <q-input
      label-slot
      v-model="form.fieldCode"
      :rules="rules.fieldCode"
      :readonly="readonly"
      maxlength="128"
      dense
      clearable
      outlined
      hide-bottom-space
    >
      <template v-slot:label>
        <span class="text-negative">*</span>
        <span>字段编码</span>
      </template>
    </q-input>
    <q-select
      label-slot
      v-model="form.fieldType"
      :rules="rules.fieldType"
      :readonly="readonly"
      :options="$dict.fieldType"
      map-options
      emit-value
      dense
      clearable
      outlined
      hide-bottom-space
    >
      <template v-slot:label>
        <span class="text-negative">*</span>
        <span>字段类型</span>
      </template>
    </q-select>
    <q-input
      v-if="form.fieldType === 2 || form.fieldType === 3"
      label-slot
      v-model="form.fieldLength"
      :rules="rules.fieldLength"
      :readonly="readonly"
      maxlength="10"
      dense
      clearable
      outlined
      hide-bottom-space
    >
      <template v-slot:label>
        <span class="text-negative">*</span>
        <span>字段长度</span>
      </template>
    </q-input>
    <q-input
      v-if="form.fieldType === 3"
      label-slot
      v-model="form.fieldAccuracy"
      :rules="rules.fieldAccuracy"
      :readonly="readonly"
      maxlength="2"
      dense
      clearable
      outlined
      hide-bottom-space
    >
      <template v-slot:label>
        <span class="text-negative">*</span>
        <span>小数位数</span>
      </template>
    </q-input>
    <q-input
      v-if="form.fieldType === 3"
      label="格式"
      v-model="form.fieldPattern"
      :readonly="readonly"
      maxlength="64"
      hint="例：【##.##】小数位数根据实际，最大2位，【##.00】小数位数始终2位"
      dense
      clearable
      outlined
      hide-bottom-space
    />
    <q-field
      v-model="form.fieldRequired"
      :rules="rules.fieldRequired"
      label="是否必填"
      :readonly="readonly"
      dense
      stack-label
      clearable
      outlined
      hide-bottom-space
    >
      <q-option-group
        v-model="form.fieldRequired"
        :options="$dict.yesNo"
        :disable="readonly"
        inline
      />
    </q-field>
    <q-field
      v-model="form.isContractName"
      :rules="rules.isContractName"
      label="是否以该字段命名材料"
      :readonly="readonly"
      dense
      stack-label
      clearable
      outlined
      hide-bottom-space
    >
      <q-option-group
        v-model="form.isContractName"
        :options="$dict.yesNo"
        :disable="readonly"
        inline
      />
    </q-field>
  </q-form>
</template>

<script>
import { getCurrentInstance, nextTick, ref, watch } from 'vue'
import { qNotBlank, qNotNull, qPositiveInteger } from '@/utils/validate-utils'

export default {
  props: {
    data: { type: Object },
    readonly: { type: Boolean, default: false }
  },
  setup (props) {
    const { proxy } = getCurrentInstance()
    const form = ref(dataToForm(props.data))
    const rules = ref({
      fieldName: [qNotBlank],
      fieldCode: [qNotBlank, validateCode],
      fieldType: [qNotNull],
      fieldLength: [qNotBlank, qPositiveInteger, validateLength],
      fieldAccuracy: [qNotBlank, qPositiveInteger, validateAccuracy],
      fieldRequired: [qNotNull],
      isContractName: [qNotNull]
    })

    function validateCode (v) {
      return /^[a-zA-Z][a-zA-Z0-9_]*$/.test(v) || '只能包含：字母、数字、下划线，必须以字母开头'
    }

    function validateLength (v) {
      return form.value.fieldType !== 3 || Number(v) <= 65 || '不能大于65'
    }

    function validateAccuracy (v) {
      return Number(v) <= Number(form.value.fieldLength) || '不能大于字段长度'
    }

    watch(() => props.data, (value) => {
      form.value = dataToForm(value)
      resetValidation()
    })

    function dataToForm (data) {
      return Object.assign({
        id: null,
        formId: null,
        fieldName: null,
        fieldCode: null,
        fieldType: null,
        fieldLength: null,
        fieldAccuracy: null,
        fieldPattern: null,
        fieldRequired: null,
        isContractName: null,
        sortNum: null
      }, data)
    }

    async function validate () {
      return await proxy.$refs.formEle.validate()
    }

    async function resetValidation () {
      nextTick(() => proxy.$refs.formEle.resetValidation())
    }

    function getForm () {
      return Object.assign({}, form.value)
    }

    async function validateAndGetForm () {
      if (await validate()) {
        return getForm()
      }
      throw new Error('Form valid error')
    }

    return {
      form,
      rules,
      validate,
      resetValidation,
      getForm,
      validateAndGetForm
    }
  }
}
</script>

<template>
  <q-page class="column">
    <div class="col-grow row" style="height:0">
      <div class="col-auto column full-height">
        <div class="col" style="height:0;">
          <div class="q-pa-sm" style="height:100%;max-width:350px;overflow:auto;">
            <q-tree
              ref="tree"
              node-key="key"
              :nodes="nodes"
              v-model:expanded="expanded"
              :selected="selected"
              @update:selected="updateSelected"
            >
              <template v-slot:default-header="prop">
                <div class="row items-center no-wrap">
                  <q-icon v-if="prop.node.type === 1" name="source" color="orange" class="q-mr-sm"/>
                  <q-icon v-if="prop.node.type === 2" name="article" color="primary" class="q-mr-sm"/>
                  <div class="text-primary">{{ prop.node.classifyName || prop.node.formName }}</div>
                </div>
              </template>
            </q-tree>
          </div>
        </div>
        <q-separator/>
        <div class="col-auto row q-gutter-sm q-pa-sm">
          <q-btn label="添加分类" color="primary" size="sm" padding="4px 8px" outline @click="toAddClassify"/>
          <q-btn label="添加材料" color="primary" size="sm" padding="4px 8px" outline @click="toAddContractForm"/>
          <q-btn label="修改" color="primary" size="sm" padding="4px 8px" outline @click="toUpdateNode"/>
          <q-btn label="删除" color="negative" size="sm" padding="4px 8px" outline @click="toDeleteNode"/>
        </div>
      </div>
      <q-separator vertical/>
      <div class="col column full-height" style="overflow:auto">
        <q-tabs
          v-model="tab"
          dense
          narrow-indicator
          class="col-auto text-grey"
          active-color="primary"
          indicator-color="primary"
          align="left"
        >
          <q-tab :name="0" label="材料字段维护"/>
          <q-tab :name="1" label="材料模板维护"/>
        </q-tabs>
        <q-separator/>
        <div v-if="currentFormId" class="col q-pa-md">
          <q-markup-table v-if="tab === 0" flat bordered dense class="field-table">
            <thead>
            <tr>
              <th class="text-left">序号</th>
              <th class="text-left">字段名称</th>
              <th class="text-left">字段编码</th>
              <th class="text-left">字段类型</th>
              <th class="text-left">是否必填</th>
              <th class="text-left">操作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(field,index) in fields" :key="index">
              <td class="text-left">{{ index + 1 }}</td>
              <td class="text-left">{{ field.fieldName }}</td>
              <td class="text-left">{{ field.fieldCode }}</td>
              <td class="text-left">{{ getFieldType(field) }}</td>
              <td class="text-left">{{ $dictLabel(field.fieldRequired, $dict.yesNo) }}</td>
              <td class="text-left">
                <q-btn label="修改" class="q-mr-sm" color="primary" size="sm" padding="4px 8px" outline
                       @click="toUpdateField(field)"/>
                <q-btn label="删除" color="negative" size="sm" padding="4px 8px" outline @click="deleteField(field)"/>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <q-btn label="添加字段" color="primary" size="sm" padding="4px 8px" outline @click="toAddField"/>
              </td>
            </tr>
            </tbody>
          </q-markup-table>
          <div v-if="tab === 1">
            <div class="row q-gutter-md">
              <q-img
                v-for="(template,index) of templates"
                :key="index"
                :ratio="1"
                width="150px"
                height="150px"
                class="rounded-borders cursor-pointer bg-grey-3"
              >
                <div class="full-height full-width row justify-center bg-grey-5" @click="downloadTemplate(template)">
                  <q-icon name="article" color="white" size="70px"/>
                </div>
                <div
                  class="absolute-bottom row justify-center items-center"
                  :title="template.fileName"
                  @click="downloadTemplate(template)"
                >
                  <div class="full-width text-subtitle2 ellipsis text-center">
                    {{ template.fileName }}
                  </div>
                </div>
                <q-btn
                  class="absolute-top-right"
                  style="pointer-events:all;background:rgba(0,0,0,0.6);border-radius:0 0 0 5px;z-index:1"
                  icon="close"
                  color="white"
                  size="xs"
                  @click.stop="removeTemplate(template)"
                  flat
                  round
                />
              </q-img>
              <q-img
                :ratio="1"
                width="150px"
                height="150px"
                class="rounded-borders"
              >
                <div class="absolute-full row justify-center items-center bg-grey-6" style="padding:0">
                  <q-btn
                    class="full-width full-height"
                    icon="cloud_upload"
                    size="lg"
                    flat
                    @click="openFileSelector"
                  />
                  <input
                    ref="file"
                    style="display:none;"
                    type="file"
                    @change="onFileChange"
                    multiple
                    accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  />
                </div>
              </q-img>
            </div>
          </div>
        </div>
      </div>
    </div>
  </q-page>
  <teleport to="body">
    <q-dialog v-model="showClassifyDialog" persistent>
      <q-card style="width:470px;max-width:90vw;">
        <q-card-section class="row items-center">
          <div class="text-h6">分类信息</div>
          <q-space/>
          <q-btn icon="close" flat round dense v-close-popup/>
        </q-card-section>
        <q-separator/>
        <q-card-section class="scroll" style="max-height:60vh">
          <classify-form :data="classify" ref="classifyFormEle"/>
        </q-card-section>
        <q-separator/>
        <q-card-section class="text-right">
          <q-btn color="primary" label="保存" unelevated @click="saveClassify"/>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="showContractDialog">
      <q-card style="width:470px;max-width:90vw;">
        <q-card-section class="row items-center">
          <div class="text-h6">材料表单信息</div>
          <q-space/>
          <q-btn icon="close" flat round dense v-close-popup/>
        </q-card-section>
        <q-separator/>
        <q-card-section class="scroll" style="max-height:60vh">
          <contract-form :data="contractForm" ref="contractFormEle"/>
        </q-card-section>
        <q-separator/>
        <q-card-section class="text-right">
          <q-btn color="primary" label="保存" unelevated @click="saveContractForm"/>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="showFieldDialog">
      <q-card style="width:470px;max-width:90vw;">
        <q-card-section class="row items-center">
          <div class="text-h6">字段信息</div>
          <q-space/>
          <q-btn icon="close" flat round dense v-close-popup/>
        </q-card-section>
        <q-separator/>
        <q-card-section class="scroll" style="max-height:60vh">
          <field-form :data="field" ref="fieldFormEle"/>
        </q-card-section>
        <q-separator/>
        <q-card-section class="text-right">
          <q-btn color="primary" label="保存" unelevated @click="saveField"/>
        </q-card-section>
      </q-card>
    </q-dialog>
  </teleport>
</template>

<script>
import { getCurrentInstance, ref, watch } from 'vue'
import ClassifyForm from './ClassifyForm'
import ContractForm from './ContractForm'
import FieldForm from './FieldForm'

export default {
  components: {
    ClassifyForm,
    ContractForm,
    FieldForm
  },
  setup () {
    const { proxy } = getCurrentInstance()
    const tab = ref(0)
    const nodes = ref([])
    const expanded = ref([])
    const selected = ref(null)
    const fields = ref([])
    const templates = ref([])
    const showClassifyDialog = ref(false)
    const classify = ref(null)
    const showContractDialog = ref(false)
    const contractForm = ref(null)
    const currentFormId = ref(null)
    const showFieldDialog = ref(false)
    const field = ref(null)

    watch(currentFormId, () => {
      loadFields()
      loadTemplates()
    })

    function updateSelected (value) {
      if (!value) {
        value = selected.value
      }
      const tree = proxy.$refs.tree
      const node = tree.getNodeByKey(value)
      if (node && node.type === 1) {
        tree.setExpanded(value, !tree.isExpanded(value))
      }
      if (node && node.type === 2) {
        currentFormId.value = node.id
      }
      selected.value = value
    }

    function getFieldType (field) {
      switch (field.fieldType) {
        case 1:
          return '整数'
        case 2:
          return `字符串(${field.fieldLength})`
        case 3:
          return `数值(${field.fieldLength},${field.fieldAccuracy})`
        case 4:
          return '日期'
        case 5:
          return '时间'
        case 6:
          return '日期时间'
      }
    }

    function toAddClassify () {
      let pid = 0
      if (selected.value) {
        const node = proxy.$refs.tree.getNodeByKey(selected.value)
        if (node) {
          pid = node.type === 1 ? node.id : node.classifyId
        }
      }
      classify.value = { pid }
      showClassifyDialog.value = true
    }

    async function saveClassify () {
      const param = await proxy.$refs.classifyFormEle.validateAndGetForm()
      await proxy.$server.post('/classify/save', param)
      proxy.$message.tipsSuccess('保存成功')
      showClassifyDialog.value = false
      loadNodes()
    }

    function toAddContractForm () {
      let classifyId = 0
      if (selected.value) {
        const node = proxy.$refs.tree.getNodeByKey(selected.value)
        if (node) {
          classifyId = node.type === 1 ? node.id : node.classifyId
        }
      }
      contractForm.value = { classifyId }
      showContractDialog.value = true
    }

    async function saveContractForm () {
      const param = await proxy.$refs.contractFormEle.validateAndGetForm()
      if (param.id) {
        await proxy.$server.post('/contractForm/updateContractForm', param)
      } else {
        await proxy.$server.post('/contractForm/createContractForm', param)
      }
      proxy.$message.tipsSuccess('保存成功')
      showContractDialog.value = false
      loadNodes()
    }

    function toUpdateNode () {
      if (!selected.value) {
        proxy.$message.tipsWarning('请选择要修改的节点')
        return
      }
      const tree = proxy.$refs.tree
      const node = tree.getNodeByKey(selected.value)
      if (node) {
        if (node.type === 1) {
          classify.value = Object.assign({}, node)
          showClassifyDialog.value = true
        } else {
          contractForm.value = Object.assign({}, node)
          showContractDialog.value = true
        }
      }
    }

    async function toDeleteNode () {
      if (!selected.value) {
        proxy.$message.tipsWarning('请选择要删除的节点')
        return
      }
      await proxy.$message.confirm('确定要删除吗？')
      const tree = proxy.$refs.tree
      const node = tree.getNodeByKey(selected.value)
      if (node) {
        if (node.type === 1) {
          await proxy.$server.delete(`/classify/delete?id=${node.id}`)
        } else {
          await proxy.$server.post('/contractForm/deleteContractForm', { id: node.id })
        }
        proxy.$message.tipsSuccess('删除成功')
        loadNodes()
      }
    }

    function toAddField () {
      field.value = { formId: currentFormId.value }
      showFieldDialog.value = true
    }

    function toUpdateField (item) {
      field.value = item
      showFieldDialog.value = true
    }

    async function saveField () {
      const param = await proxy.$refs.fieldFormEle.validateAndGetForm()
      if (param.id) {
        await proxy.$server.post('/contractFormField/updateContractFormField', param)
      } else {
        await proxy.$server.post('/contractFormField/createContractFormField', param)
      }
      proxy.$message.tipsSuccess('保存成功')
      showFieldDialog.value = false
      loadFields()
    }

    async function deleteField (field) {
      await proxy.$message.confirm('确定要删除该字段吗？')
      await proxy.$server.post('/contractFormField/deleteContractFormField', { id: field.id })
      proxy.$message.tipsSuccess('删除成功')
      loadFields()
    }

    function openFileSelector () {
      const fileEle = proxy.$refs.file
      fileEle.value = ''
      fileEle.click()
    }

    async function onFileChange () {
      const files = proxy.$refs.file.files
      for (const file of files) {
        try {
          const formData = new FormData()
          formData.append('file', file, file.name)
          const response = await proxy.$server.post('/sysFile/uploadFile', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          const fileName = file.name.replace(/\.[^.]*$/, '')
          const fileExt = file.name.substring(fileName.length)
          await proxy.$server.post('/template/save', {
            formId: currentFormId.value,
            fileName,
            filePath: response.path,
            fileExt
          })
        } catch (e) {
          proxy.$message.tipsError(`${file.name}上传失败`)
          console.error(e)
        }
        loadTemplates()
      }
    }

    async function removeTemplate (template) {
      await proxy.$message.confirm('确定要删除该模板吗？')
      await proxy.$server.delete(`/template/delete?id=${template.id}`)
      proxy.$message.tipsSuccess('删除成功')
      loadTemplates()
    }

    async function downloadTemplate (template) {
      await proxy.$message.confirm('确定要下载该模板吗？')
      window.open(template.fileUrl)
    }

    async function mapClassify (map, classifyList) {
      if (classifyList) {
        for (const classify of classifyList) {
          classify.key = `1-${classify.id}`
          classify.type = 1
          map[classify.id] = classify
          mapClassify(map, classify.children)
        }
      }
    }

    async function mergeContract (map, classifyList, contractList) {
      const roots = []
      for (const contract of contractList) {
        contract.key = `2-${contract.id}`
        contract.type = 2
        const classify = map[contract.classifyId]
        if (classify) {
          if (classify.children) {
            classify.children.push(contract)
          } else {
            classify.children = [contract]
          }
        } else {
          roots.push(contract)
        }
      }
      classifyList.push(...roots)
    }

    async function loadNodes () {
      const classifyList = await proxy.$server.get('/classify/tree')
      const contractList = await proxy.$server.post('/contractForm/contractFormList')
      const map = {}
      mapClassify(map, classifyList)
      mergeContract(map, classifyList, contractList)
      nodes.value = classifyList
    }

    async function loadFields () {
      if (currentFormId.value) {
        fields.value = await proxy.$server.post(
          '/contractFormField/contractFormFieldList', { formId: currentFormId.value })
      }
    }

    async function loadTemplates () {
      if (currentFormId.value) {
        templates.value = await proxy.$server.get(`/template/queryByfromId?formId=${currentFormId.value}`)
      }
    }

    async function init () {
      await loadNodes()
    }

    init()

    return {
      tab,
      nodes,
      expanded,
      selected,
      fields,
      templates,
      showClassifyDialog,
      classify,
      showContractDialog,
      contractForm,
      currentFormId,
      showFieldDialog,
      field,
      updateSelected,
      getFieldType,
      toAddClassify,
      saveClassify,
      toAddContractForm,
      saveContractForm,
      toUpdateNode,
      toDeleteNode,
      toAddField,
      toUpdateField,
      saveField,
      deleteField,
      openFileSelector,
      onFileChange,
      removeTemplate,
      downloadTemplate
    }
  }
}
</script>

<template>
  <q-form ref="formEle" class="q-gutter-md">
    <q-input
      label-slot
      v-model="form.classifyName"
      :rules="rules.classifyName"
      :readonly="readonly"
      maxlength="128"
      dense
      clearable
      outlined
      hide-bottom-space
    >
      <template v-slot:label>
        <span class="text-negative">*</span>
        <span>分类名称</span>
      </template>
    </q-input>
    <q-input
      label="分类编码"
      v-model="form.classifyCode"
      :readonly="readonly"
      maxlength="64"
      dense
      clearable
      outlined
      hide-bottom-space
    />
  </q-form>
</template>

<script>
import { getCurrentInstance, nextTick, ref, watch } from 'vue'
import { qNotBlank } from '@/utils/validate-utils'

export default {
  props: {
    data: { type: Object },
    readonly: { type: Boolean, default: false }
  },
  setup (props) {
    const { proxy } = getCurrentInstance()
    const form = ref(dataToForm(props.data))
    const rules = ref({
      classifyName: [qNotBlank]
    })

    watch(() => props.data, (value) => {
      form.value = dataToForm(value)
      resetValidation()
    })

    function dataToForm (data) {
      return Object.assign({
        id: null,
        pid: 0,
        classifyName: null,
        classifyCode: null
      }, data)
    }

    async function validate () {
      return await proxy.$refs.formEle.validate()
    }

    async function resetValidation () {
      nextTick(() => proxy.$refs.formEle.resetValidation())
    }

    function getForm () {
      return Object.assign({}, form.value)
    }

    async function validateAndGetForm () {
      if (await validate()) {
        return getForm()
      }
      throw new Error('Form valid error')
    }

    return {
      form,
      rules,
      validate,
      resetValidation,
      getForm,
      validateAndGetForm
    }
  }
}
</script>
